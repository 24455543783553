<template>
  <div>
    <ClassTitle
      class="m-t-40 m-b-20"
      title="免费公开课"
      imgSrc="http://webxiaozhu.oss-cn-beijing.aliyuncs.com/images/%E5%85%8D%E8%B4%B9%E5%85%AC%E5%BC%80%E8%AF%BE2ElYEU"
    />
    <el-row v-if="freeClassList.length > 0">
      <!-- 列表循环开始 -->
      <el-col
        :span="6"
        class="bg-white classType openClass"
        v-for="(item, index) in freeClassList"
        :key="item.id"
        :style="{
          marginLeft: index % 4 != 0 ? '1.6rem' : '',
          marginTop: index > 3 ? '1.6rem' : '',
        }"
        style="width: 23.4%"
        @click.native="goDetial(item)"
      >
        <!-- <div> -->
        <p class="title text-l" style="margin-top: 0">{{ item.courseTitle }}</p>
        <p class="time text-l yichuOne" style="margin: 0">
          {{ item.countStarttime }}至{{ item.countStopTime }} |
          {{ item.ccount }}课时
        </p>
        <!-- 老师头像循环列表 -->
        <el-row>
          <div>
            <div
              class="teacherPic"
              v-for="(t, index) in item.tlist"
              :key="index"
            >
              <el-avatar :src="t.turl"></el-avatar>
              <p class="teacherName">{{ t.tname }}</p>
            </div>
          </div>
        </el-row>
        <!-- 预约 -->
        <div class="f-s-14 m-t-20 b-t-1 p-t-10">
          <div class="f-l-l" style="height: 40px; line-height: 40px">
            {{ item.courseXnum }}人已上课
          </div>
          <el-button
            icon="el-icon-video-play"
            :type="item.type == 1 ? 'warning' : 'danger'"
            class="f-l-r b-r-18 class-btn-shadow"
            :disabled="item.type != 1"
            style="
              background: linear-gradient(
                90deg,
                #ff8a18 0%,
                #f7ae6d 0%,
                #f08041 100%
              );
              padding: 10px;
              box-shadow: 0px 2px 4px 0px rgba(240, 128, 65, 0.3);
              min-width: 80px;
            "
            >{{ item.type == 1 ? "免费" : "已售罄" }}</el-button
          >
        </div>
        <!-- </div> -->
      </el-col>
    </el-row>

    <el-row class="text-l" v-else>
      <el-col
        class="openClass classType"
        style="width: 23.4%"
        v-for="(item, index) in skeletonNum"
        :key="index"
        :span="6"
        :style="{
          marginLeft: index % 4 != 0 ? '1.6rem' : '',
          marginTop: index > 3 ? '1.6rem' : '',
        }"
      >
        <el-skeleton style="height: 285px" :loading="true" animated :rows="8">
          <el-skeleton-item style="width: 100%; height: 285px" />
        </el-skeleton>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  props: {
    freeClassList: Array,
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      skeletonNum: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
      ],
    };
  },
  methods: {
    goDetial(item) {
      let { href } = this.$router.resolve({
        path: "/course/toOneCenter",
        query: { cid: item.cid },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped>
.classType {
  padding: 30px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 5%);
  border-radius: 10px;
  transition: all 0.3s;
  /* margin-right:30px */
}
.classType:hover {
  top: -10px;
  box-shadow: 0px 8px 17px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
}
.title {
  height: 46px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}
.time {
  font-size: 14px;
  color: #666;
  height: 36px;
  margin-bottom: 10px;
}
.teacherPic {
  width: 46px;
  height: 46px;
  float: left;
  display: block;
  margin-right: 20px;
  margin-bottom: 16px;
}
.teacherName {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  margin: 6px 0;
}
</style>
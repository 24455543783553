<template>
  <div>
    <!-- <h1 class="m-t-40">精品课</h1> -->
    <ClassTitle
      class="m-t-40 m-b-20"
      title="精品付费课"
      imgSrc="http://webxiaozhu.oss-cn-beijing.aliyuncs.com/images/%E7%B2%BE%E5%93%81%E8%AF%BEhFHqnB"
    />
    <el-row>
      <el-col :span="12" class="m-r-15" style="width: 49%; max-height: 300px">
        <el-image :src="imgArray.url" style="width: 100%">
          <div slot="error" class="image-slot">
            <el-skeleton style="width: 100%" :loading="true" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 300px"
                />
              </template>
            </el-skeleton>
          </div>
        </el-image>
      </el-col>
      <!-- 列表循环开始 -->
      <Item :ExcellentClassList="ExcellentClassList" :type="type" />
    </el-row>
  </div>
</template>


<script>
import Item from "@/components/public/ExcellentClass/item";
export default {
  components: {
    Item,
  },
  data() {
    return {
      imgArray: [{ url: "" }], // 存放图片
      ExcellentClassList: [], // 存放精品课程列表
      type: "home",
    };
  },
  created() {
    this.getHomeImg(); // 获取精品课程下面的图片
    this.getExcellentClassList(); // 获取精品课程
  },
  methods: {
    getHomeImg() {
      // 获取精品课程下面的图片
      this.Api.Login.getHomeImg().then((res) => {
        if (res.status == 200) {
          this.imgArray = res.data;
        }
      });
    },
    getExcellentClassList() {
      // 获取精品课程的列表
      this.Api.Login.getExcellentClassList().then((res) => {
        if (res.status == 200) {
          this.ExcellentClassList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
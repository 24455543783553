<template>
  <div id="app">
  <img alt="logo" src="http://webxiaozhu.oss-cn-beijing.aliyuncs.com/images/logoaWIQJh" style="display: none;" title="logo" />
    <div id="header">
      <Header v-if="path" @setheight="getininfo"/>
    </div>
    <div :style="{height: height}" v-if="path"></div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="contentH"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="contentH"></router-view>
    <Suspension v-if="suspensionPath" />
    <Footer v-if="footPath" />
    <Login />
    <!-- 消息中心组件 -->
    <Msg v-if="path" />
  </div>
</template>

<script>
import { v4 } from "uuid";
export default {
  data() {
    return {
      height:'0px',
      ws: "",
      wsType: "",
      wsData:""
    };
  },
  created() {
    window.addEventListener("setItem", () => {
      this.wsData =sessionStorage.getItem("watchStorage");
    });
    let belong = this.$route.query.belong;
    if (this.$f.isNotNull(belong) && belong != this.$f.getLocal("belong")) {
      this.$f.setLocal("belong", belong);
    }
    this.webstock();
    this.getininfo();
  },
  methods: {
    getininfo(){
      if(this.getCookie('xzjytipinfo')==1){
        this.height="80px";
      }else{
        this.height="112px";
      }
      
    },
    getCookie(name) {
      var reg = RegExp(name + '=([^;]+)');
      var arr = document.cookie.match(reg);
      if (arr) {
          return arr[1];
      } else {
          return '';
      }
    },
    webstock() {
      let that = this;
      if (!this.GLO.wsType) return false;
      if ("WebSocket" in window) {
        if(this.$f.getLocal("user") != undefined) {
          this.$f.setSettiong('onlyId',this.$f.getLocal("user").uid)
        }
        let uuid = v4();
        let onlyId = ""  // 推送的唯一标识
        if(!this.$f.isNotNull(this.$f.getSession('onlyId'))){  // 如果本地没有存储唯一标识
          onlyId = v4()  // 生成唯一标识
          this.$f.setSettiong('onlyId',onlyId)  // 存储唯一标识
        } else {  // 如果本地存储了唯一标识
          onlyId = this.$f.getSession('onlyId')  // 获取唯一标识 
        }
        let equipment = "pc";
        let loginType = this.$f.getLocal("user") != undefined ? true : false;
        let href = document.location.href.replace(this.GLO.locaHref,'')
        if(!this.$f.isNotNull(href)) href = null
        if(href != null) {
          href = href.replace("/","-")
          href = href.replace("?","-")
        }
        this.ws = new WebSocket("ws://" +this.GLO.wsIp +"/imserver/" + uuid + "/" +equipment +"/" + loginType +"/" + this.$f.getSession("ip") + "/" + onlyId + "/" + href);
      } else {
        alert("浏览器不支持");
      }
      this.ws.onerror = () => {
        console.log("链接失败");
      };
      this.ws.onopen = () => {
        console.log("成功了哦！");
      };
      this.ws.onmessage = (e) => {
        this.getMsg(e);
      };
      this.ws.onmessage = function (msg) {
        if (msg.data == "连接成功" || !that.$f.isNotNull(msg)) return false;
        let res = JSON.parse(msg.data);
        console.log(res)
        // that.resetSetItem('watchStorage',msg.data);

        if(res.type == '1'){
          that.forcedReturn()
        }
        //发现消息进入    开始处理前端触发逻辑
      };
      window.onbeforeunload = function () {
        this.ws.close();
      };
    },
    forcedReturn() {
      let that = this;
      that.$alert("您的账号已被强制退出", "小筑教育", {
        confirmButtonText: "确定",
        callback: () => {
          that.$f.removeLocal([
            "token",
            "userInfo",
            "setToken",
            "loginPhone",
            "user",
            "uid",
          ]);
          that.$store.commit("overall/editLoginType", false);
          that.$store.commit("overall/editUser", {});
          that.$router.replace({ path: "/" });
          location.reload();
        },
      });
    },
    getMsg(e) {
      console.log(e);
    },
    send() {
      if (typeof WebSocket == "undefined") {
        console.log("您的浏览器不支持WebSocket");
      } else {
        console.log(this.ws);
        console.log("您的浏览器支持WebSocket");
        this.ws.send('{toUserId:"user000",contentText:"测试一下"}');
      }
    },
  },
  computed: {
    // wsData() {
    //   return this.$store.state.ws.wsData;
    // },
    user() {
      return this.$store.state.overall.user;
    },
    path() {
      let p = this.$route.path;
      if (p == "/live/getLiveBroadcast" || p == "/lookLive" || p == "/player") {
        return false;
      }
      return true;
    },
    footPath() {
      let p = this.$route.path;
      if (
        p == "/live/getLiveBroadcast" ||
        p == "/EnterpriseTrain/toEnterpriseTrain" ||
        p == "/lookLive" || p == "/player"
      ) {
        return false;
      }
      return true;
    },
    suspensionPath() {
      let p = this.$route.path;
      if (
        p == "/live/getLiveBroadcast" ||
        p == "/player" ||
        p == "/learningCenter" ||
        p == "/lookLive"
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    wsData: {
      deep: true,
      immediate: false,
      handler(val) {
        console.log(val)
        if (val == "") return false;
        if (val.type == "1") {
          // 强制当前用户退出登录
          this.forcedReturn();
        }
      },
    },
  },
};
</script>

<style>
.contentH {
  min-height: calc(100vh - 362.08px);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2001;
  background: white;

box-shadow: 0px -1px 3px 1px rgba(0, 0, 0, 0.1);

}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
